









































































































































































































































































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { reactive } from '@vue/composition-api';
import I18n from '@/i18n/index';
import { Inquiry } from './Index.vue';
import { InquiryStore } from '@/repository/kontent/stores/inquiryStore';

@Component({
  components: {},
})
export default class Index extends Vue {
  @Prop(Object) inquiry!: Inquiry;

  private i18n = I18n;
  private inquiryType = reactive(new InquiryStore());

  @Watch('i18n.language')
  onTextChanged(): void {
    this.inquiryType = reactive(new InquiryStore());
    this.inquiryType.restore();
  }

  private created() {
    this.inquiryType.restore();
  }
  private get inquiryDict(): Array<string> {
    const inquiryList = this.inquiryType.list[0]?.inquirytypelist.value;

    let result: Array<string> = [];
    if (inquiryList) {
      result = inquiryList.map((i) => {
        return i.inquirytitle.value;
      });
    }
    return result;
  }
}
