import Vue from 'vue';
import Store from './base/store';
import { Service } from "../models/service";
import { Inquirygroup } from '../models/inquirygroup'


class InquiryStore extends Store<Inquirygroup>{
  constructor(){
    super('inquirygroup')
  }
}


export { InquiryStore, Service };
